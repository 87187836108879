import store from '@/state/store'

export default [{
        path: '/',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                next({
                    name: 'calendar'
                })
            },
        },
        name: 'home',
        component: () => import('./views/home'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./views/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./views/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password/:resetCode',
        name: 'reset-password',
        props: true,
        component: () => import('./views/account/reset-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut')
                debugger
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? {
                    name: 'default'
                } : {
                    ...routeFrom
                })
            },
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('./views/account/profile'),
        meta: {
            authRequired: true,
        }
    },    
    {
        path: '/change-password',
        name: 'change-password',
        component: () => import('./views/account/change-password'),
        meta: {
            authRequired: true,
        }
    },    
    {
        path: '/tools/file',
        name: 'file',
        component: () => import('./views/tools/file'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/tools/file/:filePath',
        name: 'filePath',
        props:true,
        component: () => import('./views/tools/file'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/statistics/workingday',
        name: 'file',
        component: () => import('./views/statistics/workingday'),
        meta: {
            authRequired: true,
        }
    },    
    {
        path: '/hr/staff-management',
        name: 'staff-management',
        component: () => import('./views/hr/staff'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./views/calendar/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/schedule-plan',
        name: 'schedule-plan',
        component: () => import('./views/calendar/verification'),
        meta: {
            authRequired: true,
        },
    },    
    {
        path: '/client',
        name: '客户管理',
        component: () => import('./views/clients/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/client/profile/:clientNo',
        name: 'client-profile',
        props: true,
        component: () => import('./views/clients/profile'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/traning',
        name: 'traning',
        component: () => import('./views/traning/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/traning/detail',
        name: 'traning-detail',
        component: () => import('./views/traning/detail'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('./views/orders/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/service-order',
        name: 'service-order',
        component: () => import('./views/serviceorders/index'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/service-order/detail/:serviceId',
        name: 'service-order-detail',
        props: true,
        component: () => import('./views/serviceorders/detail'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/order/higg-form',
        name: 'order-higg-form',
        component: () => import('./views/orders/higg-form'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/order/slcp-form',
        name: 'order-slcp-form',
        component: () => import('./views/orders/slcp-form'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/order/aws-form',
        name: 'order-aws-form',
        component: () => import('./views/orders/aws-form'),
        meta: {
            authRequired: true,
        },
    },    
    {
        path: '/order/zero-level2-form',
        name: 'order-zero-level2-form',
        component: () => import('./views/orders/zero-level2-form'),
        meta: {
            authRequired: true,
        },
    },    
    {
        path: '/order/zdhc-level1-form',
        name: 'order-zdhc-level1-form',
        component: () => import('./views/orders/zdhc-level1-form'),
        meta: {
            authRequired: true,
        },
    },    
    {
        path: '/order/ghg-form',
        name: 'order-ghg-form',
        component: () => import('./views/orders/ghg-form'),
        meta: {
            authRequired: true,
        },
    },        
    {
        path: '/order/general-form',
        name: 'order-general-form',
        component: () => import('./views/orders/general-form'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/follow-up',
        name: 'follow-up',
        component: () => import('./views/orders/follow-up'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('./views/orders/invoice'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/order/detail',
        name: 'order-detail',
        component: () => import('./views/orders/detail'),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/pages/blank-page',
        name: 'Blank page',
        component: () => import('./views/pages/blank'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/pages/faqs',
        name: 'FAQs',
        component: () => import('./views/pages/faqs'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/errors/403',
        name: 'Errors-403',
        component: () => import('./views/pages/error-403'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/errors/404',
        name: 'Errors-404',
        component: () => import('./views/pages/error-404'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/errors/500',
        name: 'Errors-500',
        component: () => import('./views/pages/error-500'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/pages/lock-screen1',
        name: 'Lock-screen 1',
        component: () => import('./views/pages/lock-screen1'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/pages/lock-screen2',
        name: 'Lock-screen 2',
        component: () => import('./views/pages/lock-screen2'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/charts/echart',
        name: 'Echart chart',
        meta: { authRequired: true },
        component: () => import('./views/charts/echart/index')
    },
]