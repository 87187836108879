import { login } from '@/apis/auth'
import { userinfo } from '@/apis/user'
export const state = {
  currentUser: JSON.parse(localStorage.getItem('auth.currentUser')),
  token:localStorage.getItem('auth.token'),
  language:localStorage.getItem('language')
}

export const mutations = {
  SET_LANGUAGE(state,language) {
    state.language = language
    saveState('auth.language', language)
  },
  SET_CURRENT_USER(state,user) {
    state.currentUser = user
    saveState('auth.currentUser', user)
  },
  CLEAR_CURRENT_USER(state){
    state.currentUser = null
    removeState('auth.currentUser')
  },
  SET_TOKEN(state, newValue) {
    state.token = newValue
    saveState('auth.token', newValue)
  },
  CLEAR_TOKEN(state) {
    state.token = null
    removeState('auth.token')
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.token
  },
  currentUser(state) {
    return state.currentUser
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate')
  },
  setLanguage({ commit},data) {
    commit('SET_LANGUAGE',data);
  },
  refreshUserInfo({commit}){
    userinfo().then((res)=>{
      if(res.code==200){
        commit('SET_CURRENT_USER',res.data);
      }else{
        throw res.message
      }
    });
  },
  // Logs in the current user.
  logIn({ commit,dispatch},data = {}) {
    return login(data).then((res)=>{
      if(res.access_token){
        const { access_token, token_type } = res;
        const accessToken = token_type.charAt(0).toUpperCase() + token_type.slice(1) + ' ' + access_token;
        commit('SET_TOKEN',accessToken);
        dispatch('refreshUserInfo');
      }else{
        throw res.message
      }
    })
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit('CLEAR_CURRENT_USER')
    commit('CLEAR_TOKEN')
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  register({ commit, dispatch, getters }, { data } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    // return getFirebaseBackend().registerUser(email, password).then((response) => {
    //   const user = response
    //   commit('SET_CURRENT_USER', user)
    //   return user
    // });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    // return getFirebaseBackend().forgetPassword(email).then((response) => {
    //   const message = response.data
    //   return message
    // });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.token) return Promise.resolve(null)
  },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
  let value = state
  if(typeof(state)=="object"){
    value = JSON.stringify(state)
  }
  localStorage.setItem(key, value)
  
}
function removeState(key) {
  localStorage.removeItem(key)
}
