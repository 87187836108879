const zh={
    common:{
        yes:"是",
        no:"否",
        verfRequirement:"验证信息",
        baseInfo:"企业基本资料",
        clientNameLocal:"公司名称(当地语言)",
        clientNameLocalError:"公司名称不能为空",
        clientNameEn:"公司名称(英文)",
        addressLocal:"地址(使用当地语言)",
        addressLocalError:"地址不能为空",
        addressEn:"地址(英文)",
        industry:"所属行业",
        businessType:"企业性质",
        pleaseDetail:"请说明",
        contactInfo:"填报人信息",
        contact:"联系人",
        contactError:"联系人不能为空",
        mobile:"手机号码",
        mobileError:"手机号码不能为空",
        email:"邮箱地址",
        emailError:"邮箱地址不能为空",
        countryError:"请选择国家",
        taxCodeError:"纳税人识别号不能为空",
        title:"职位",
        titleError:"职位不能为空",
        submit:"提交",
        hotline:"服务热线",
        moreService:"更多服务",
        successMsg:"您的申请已提交，我们将尽快联系您，服务热线：+86 13662650451",
        view:"查看",
        selectFile:"选择文件", 
        verificationDate:"验证日期",
        pleaseSelectFile:"请选择文件",
        download:"下载",
        delete:"删除",
        uploadFile:"上传文件",
        createDate:"创建时间",
        updateDate:"修改时间",
        ok:"确认",
        cancel:"取消",
        areyousure:"确认吗？",
        contactDetails:"联系方式",
        assignCompleted:"分配已完成",
        taxCode:"纳税人识别号",
        country:"国家",
        province:"省份",
        station:"周边高铁站或机场名称",
        requiredError:"这是必填项，请输入"        
    },
    generalService:{
        title:"常规服务",
        serviceInfo:"服务信息",
        serviceType:"服务类型",
        serviceDate:"期望服务日期",
        serviceDateError:"期望服务日期不能为空"
    },
    fem:{
        pageTitle:"Higg FEM 2023 验证申请",
        formName:"Higg FEM 2023 验证申请",
        type:"验证类型",
        typeFoundation:"Foundation - 不参与评分，验证过去12个月的数据和记录",
        typeFEM:"FEM - 有评分系统，验证过去一个自然年的数据和记录，请勾选下面各模块自评完成度",
        self:"自评完成度",
        envManagementSystem:"环境管理体系",
        energyGreenhouseGases:"能源和温室气体",
        waterResources:"水资源",
        wastewater:"废水",
        exhaustEmissions:"废气排放",
        waste:"废弃物",
        chemicalManagement:"化学品管理",
        experience:"验证经验",
        experience1:"首次",
        experience2:"第二次",
        experience3:"第三次和以上",
        model:"验证模式",
        model1:"环境评估现场验证",
        model2:"环境评估远程验证（*需贵司征求最终品牌客户的同意）",
        selfassessment:"自我评估已经完成",
        promoteBrand:"推动客户",
        auditDate:"期望验证日期",
        auditDateError:"期望验证日期不能为空",
        productType:"产品类型和产量",
        technology:"主要生产工艺（可提供工艺流程图代替）",
        chemical:"生产过程中是否使用化学品？举例",
        wwt:"是否有废水处理站",
        coverarea:"占地面积",
        coverareaError:"请填写面积",
        employee:"员工人数",
        employeeError:"请填写员工人数",
        esc:"环境体系证书"
    },
    slcp:{
        formName:"SLCP 验证申请",
        type:"验证需求",
        experience:"验证经验",
        experience1:"首次验证",
        experience2:"年度验证",
        experience3:"跟进验证",
        selfassessment:"自我评估已经完成",
        postDate:"自评提交日期",
        ahSystem:"AH托管平台",
        other:"其他",
        promoteBrand:"推动客户",
        announcementType:"通知类型",
        announcementType1:"通知",
        announcementType2:"不通知",
        announcementType3:"半通知（10天）",
        auditDate:"期望验证日期",
        auditDateError:"期望验证日期不能为空",
        model:"验证形式",
        model1:"现场验证",
        model2:"视频验证",
        model3:"现场验证+视频验证",
        apsca:"是否需要APSCA验证员",
        employee:"工人人数",
        employeeError:"工人人数必填",
        manager:"主管人数",
        managerError:"主管人数必填",
        csr:"CSR管理体系证书（如：BSCI、WRAP、ISO等）",
        serviceProvider:"企业现场其他服务供应商",
        productionBuildings:"生产建筑数量",
        dormitories:"宿舍数量",
        warehouses:"仓库数量",
        otherBuildings:"其他建筑数量",
        productType:"主要产品",
        technology:"主要工序",
        times:"次",
        tipWorker:"温馨提醒:请根据工人的定义确保填报的人数是准确的",
        tipManager:"温馨提醒:确保填报的主管人数是准确的",
    },
    application_confirm:{
        applicationForm:"申请书",
        applicationFormUpload:"服务申请书上传",
        notExist:"申请单不存在",
        error:"申请单异常",
        success:"申请书已提交,我们将尽快提供报价，服务热线：+86 13662650451",
        submitError:"申请书提交异常, 请联系我们: +86 13662650451",
        pleaseSelectfile:"请选择签字盖章申请书"
    },
    confirm_letter:{
        confirmLetter:"确认函回执",
        notExist:"服务单不存在",
        error:"服务单异常",
        success:"排期已确认，服务热线：+86 13662650451",
        submitError:"提交异常, 请联系我们: +86 13662650451",
    },
    verfDocument:{
        verfDocument:"验证文档",
        pre:"预审文档",
        other:"其他文档",
        file:"文件名",
        updateTime:"更新时间",
        size:"文件大小",
        status:"预审状态",
        comments:"预审结论",
        preTitle:"Higg FEM2023预审包括",
        pre1:"能源: 一年能耗清单excel表（2021-2023）,能源/燃料采购发票/读表记录（2021-2023）",
        pre2:"水: 一年水源和用水量清单excel表（2021-2023）,用水采购发票/读表记录（2021-2023）",
        pre3:"废水:工业废水/生活废水量清单(2023),发票/读表记录（2023）,估算方法（若适用）",
        pre4:"废气: 各种制冷剂清单以及用量Excel表（2023）,发票/读表记录（2023）,估算方法（若适用）",
        pre5:"废弃物:非危废清单以及产生量excel表（2023）,危废清单以及产生量excel表（2023）,称重记录/发票/处理记录,估算方法（若适用）",

    },
    traningSurvey:{
        title:"可持续管理培训调查问卷",
        question1:"1、您参加的培训课题",
        question2:"2、您参加培训的时间",
        question3:"3、此次课程内容是否解答您的项目疑问？",
        question4:"4、此次课程内容对您项目工作实施是否有指导作用？",
        question5:"5、此次课程对您环境管理工作理解及实施是否有关联性、启发性？",
        question6:"6、此次课程教材设置是否具备结构条理性，易于检索？",
        question7:"7、课程授课讲师是否逻辑清晰、重点突出？",
        question8:"8、此次课程授课讲师是否融入丰富的良好案例及练习？",
        question9:"9、此次课程讲师是否良好把握课程进度？",
        question10:"10、请对此次课程行政人员态度、责任心及服务总体评价！",
        question11:"11、请对此次培训工具便捷性、易操作性进行评价！",
        question12:"12、如有其他建议及补充，欢迎提出！",
        nonExist:"培训课程不存在",
        error:"数据异常",
        success:"感谢您的反馈",
        submiterror:"数据提交异常，请联系管理员:+86 13662650451",
        nameerror:"课程名称不能为空",
        dateerror:"课程时间不能为空"
    },
    menu:{
        scheduleplan:"审核排期",
        calendar:"日历",
        order:"订单管理",
        "service-order":"服务单管理",
        tool:{
            name:"工具",
            netdisk:"网盘",
            traning:"培训"
        },
        statistics:{
            name:"统计",
            workingday:"工时统计",
        }

    },
    actions:{
        new:"新建",
        view:"查看",
        edit:"修改",
        status:"状态",
        followup:"跟进",
        delete:"删除",
        actions:"操作",
        refresh:"刷新",
        back:"返回",
        share:"分享",
        submit:"提交",
        search:"搜索",
        download:"下载",
        close:"关闭",
        copy:"复制",
        cancel:"取消",
        ok:"确定",
        add:"新增",
        createAppForm:"生成申请表",
        downAppForm:"下载申请表",
        send:"发送",
        createQuotaion:"生成报价书",
        createContract:"生成合同",
        archivingContract:"归档合同",
        archivingApplicationForm:"归档申请表",
        invoice:"开票信息",
        downContract:"下载合同",
        bindingClient:"绑定客户",
        newClient:"新建客户",
        binding:"绑定",
        assignSales:"分配销售",
        assignCS:"分配客服",
        createInvoice:"生成形式发票",
        archivingInvoice:"归档形式发票",
        downInvoice:"下载形式发票",
        internalSharing:"内部分享",
        feedback:"反馈",
        confirm:"确认",
        argue:"争议"
    },
    quotation:{
        title:"报价",
        language:"报价书语种",
        languageCn:"中文",
        languageEn:"英文",
        currencyType:"币种",
        personDays:"人天",
        vat:"税率",
        detail:"费用明细",
        total:"总计",
        add:"增加",
        subtract:"抵扣",
    },
    order:{
        country:"国家",
        pageTitle:"订单管理",
        orderNo:"订单编号",
        type:"订单类型",
        status:"订单状态",
        payment:"支付",
        invoice:"开票",
        source:"订单来源",
        applicationDate:"申请时间",
        new:"新订单",
        myApplicationForm:"专属申请单",
        general:"常规服务",
        fem:"Higg FEM验证",
        slcp:"SLCP验证",
        aws:"AWS辅导",
        zero:"SUPPLIER TO ZERO LEVEL 2 REVIEW",
        zdhcil1:"ZDHC VERIFIED INCHECK LEVEL 1",
        ghg:"GHG Inventory",
        errorNoQuotation:"没有找到报价单,请创建!",
        errorCreateFail:"创建失败,请联系管理员",
        infoFileUpload:"文件已更新",
        infoAppDeleteAlert:"申请单删除将无法撤回",
        errorOrderExits:"订单不存在",
        errorRequest:"请求异常，请联系管理员",
        infoConfirmNewQuotation:"确认新增报价吗?",
        infoHisQuotatiton:"操作将会导致历史报价失效!",
        infoQuotationSent:"报价已发送，注意后续跟进!",
        errorSent:"发送失败，请联系管理员",
        infoSure:"确定吗？",
        infoOperation:"操作将不可撤回",
        errorUpdate:"更新失败!", 
        infoBindAlert:"请先绑定客户档案!",
        infoQuoteAlert:"请先创建报价单!",
        infoPaymentStatus:"标记支付需要上传支付凭证，并且同步创建服务单",
        statusSettings:"状态设置",
        paymentVoucher:"支付凭证",
        followupRecord:"跟进记录",
        infoEnterContent:"请输入沟通内容",
        infoCreateClient:"为该订单创建新的客户档案",
        infoBindOrder:"绑定订单到该客户档案",
        errorBindingFail:"绑定失败",
        infoEnterClientName:"请输入客户名称",
        infoNoRecord:"无相关记录",
        errorNoPaid:"订单未支付",
    },
    invoice:{
        infoUpdated:"开票信息已更新",
        infoEmail:"开票申请邮件已发出，请及时跟进客户",
        infoStatus:"开票状态已更新",
        title:"开票信息",
        sendEmail:"发送开票申请表",
        export:"导出开票申请表",
        status:"已开票",
        loadHistory:"载入历史开票信息",
        companyName:"企业名称",
        taxNumber:"纳税人识别号",
        address:"地址",
        phoneNumber:"电话",
        bank:"开户银行",
        accountNumber:"账号",
        express:"快递信息",
        recipient:"收件人",
        billNo:"单号",
        errorCompanyName:"企业名称不能为空",
        errorTaxNumber:"纳税号不能为空",
        errorAddress:"地址不能为空",
        errorPhone:"电话不能为空",
        errorBank:"开户行不能为空",
        errorAccount:"账号不能为空",
        errorRecipient:"收件人不能为空",
    },
    client:{
        name:"客户名称"
    },
    serviceOrder:{
        pageTitle:"服务单管理",
        serviceNo:"服务单号",
        type:"服务类型",
        status:"服务状态",
        personday:"人天",
        serviceDate:"排期日期",
        auditor:"审核员",
        tabBase:"基本信息",
        tabOrder:"订单信息",
        tabPreVerified:"预审文件",
        tabFactory:"客户文件",
        tabVerification:"工作文件",
        detail:"服务单明细",
        stepPS:'待排期', 
        stepSC:'排期确认', 
        stepP:'待服务', 
        stepIP:'服务中', 
        stepPP:'报告编写', 
        stepPR:'报告审核', 
        stepRF:"报告审查完成",
        stepF:'完成',
        guideline:'指南',
        confirmationLetter:"确认函",
        reconfirmationLetter:"确认函回执",
        operationLog:"日志",
        gConfirmationLetter:"生成确认函",
        sConfirmationLetter:"发送确认函",
        reviewCompletion:"报告审核完成",
        sReportUpload:"发送报告上传通知",
        sPreVerified:"发送预审通知",
        sSurvey:"发送问卷调查",
        survey:"问卷调查",
        schedulingInfo:"排期信息",
        btSchedule:"排期",
        contactInfo:"联系信息",
        verificationStartDate:"服务开始日期",
        verificationEndDate:"服务结束日期",
        announcedType:"通知类型",
        announcedStartDate:"通知开始日期",
        announcedEndDate:"通知结束日期",
        leadVerifier:"主审",
        travelSchedule:"主审差旅",
        quantitativeMetricsVerifier:"组员",
        travelScheduleQM:"组员差旅",
        observer:"观察者",
        travelScheduleObserver:"观察者差旅",
        scheduleConfirmationDate:"排期确认时间",
        uploadFile:"上传文件",
        announced:"通知",
        semiAnnounced:"半通知",
        unannounced:"不通知",
        infoCompleted:"服务单完成后将冻结操作",
        infoWaitingReportReviewerConfirm:"等待报告审核员确认报告",
        infoWorkFileUpload:"请确认已上传工作文件",
        infoReviewRequest:"报告审核申请邮件已发出，请等待分配，并及时归档文件",
        infoStatusSet:"状态设置成功",
        infoConfirmCreateLetter:"确认函已存在，是否重新创建?",
        errorGenerateConfirmationLetter:"确认函生成失败，请联系管理员!",
        infoConfirmationGenerated:"确认函生成成功!",
        infoEmailSent:"邮件已发送，注意后续跟进!",
        errorEmailSent:"发送失败，请联系管理员",
        infoReportReviewsuccess:"报告审核设置成功",
        infoConfirmReportReview:"请确认报告审核结论已上传到工作文件中",
        infoReportReviewCompletion:"报告审核完成通知已发送",
        errorSendReportReview:"邮件通知发送失败",
        errorRetrievinTemplate:"邮件通知模版获取错误",
        confirmManday:"请确认工人数量和验证人天是否符合SLCP规定"
    },
    netdisk:{
        pageTitle:"网盘",
        folder:"文件夹",
        file:"文件",
        name:"名称",
        size:"大小",
        owner:"所属",
        myshare:"我的分享",
        uploadfile:"上传文件",
        folderName:"目录名称",
        fileName:"文件名称",
        discription:"描述",
        ispublic:"是否公开",
        specifiedusers:"指定用户可见",
        shareName:"分享名称",
        shareDate:"分享日期",
        expiryDate:"有效期",
        shareLink:"分享链接",
        shareType:"分享形式",
        allowUploading:"允许上传",
        permanent:"永久有效",
        create:"创建连接",
        shareInformation:"分享信息",
        needCode:"有提取码",
        noNeedCode:"无提取码",
        code:"提取码",
        day:"天",
        errorNeedCode:"提取码不能为空"
    },
    email:{
        to:"收件人",
        cc:"抄送",
        bcc:"密送",
        subject:"主题",
        attachment:"附件"
    },
    survey:{
        customerservice:"Customer Service",
        ourservice:"Our Service",
        greenpont:"GreenPont",
        suggestion:"建议"
    },
    workingday:{
        name:"工时统计",
        detail:"统计详情",
        cycle:"统计周期",
        auditor:"验证员",
        status:"确认状态",
        confirmDate:"确认时间",
        feedback:"输入你的疑问",
        wdVerificationDays:"工作日验证天数",
        hdVerificationDays:"假日验证天数",
        wdTravelDays:"工作日差旅天数",
        hdTravelDays:"节假日差旅天数",
        wdObservationDays:"工作日观察天数",
        hdObservationDays:"节假日观察天数",
        wdObservationoftd:"工作日观察差旅天数",
        hdObservationoftd:"节假日观察差旅天数"
    },
    aws:{
        formName:"AWS项目认证申请表",
        applicantInformation:"申请方信息",
        facility:"场所",
        desiredCertificationLevel:"期望审核级别",
        certificationType:"认证类型",
        auditType: "审核类型",
        factoryName:"工厂名称",
        referenceNumber:"AWS 参考编号",
        address:"地址",
        employees:"员工数目",
        occupiedArea:"占地面积（单位：平方米）",
        mainProducts:"主要产品/服务",
        mainManufacture:"大致生产工序流程",
        wastewater:"是否产生工业废水",
        annualWater:"年度用水量（单位：吨）",
        sourceWater:"用水来源(选择所有适用的)",
        wastewaterReused:"是否有回用水/循环用水设备<br>如果有，请大致描述：<br>-	每月回用/循环利用多少吨废水？<br>-	回用/循环利用的主要过程是什么？<br>-回用/循环水利用在哪里？",
        wastewaterTreatmentPlant:"是否有废水处理装置？",
        dischargedWastewater:"废水排放去向?",
        reason:"理由",
        certLevError:"请选择期望审核级别",
        certTypeError:"请选择认证类型",
        auditTypeError:"请选择审核类型",
        factoryNameError:"请输入工厂名称",
        addressError:"请输入工厂地址",
        employeesError:"请输入员工数目",
        occupiedAreaError:"请输入占地面积",
        mainProductsError:"请输入主要产品/服务",
        mainManufactureError:"请输入大致生产工序流程",
        wastewaterError:"请输入是否产生工业废水"
    },
    zero:{
        formName:"SUPPLIER TO ZERO LEVEL 2 REVIEW申请",
        experience:"Supplier to Zero level 2 验证经验",
        finishLevel1:"是否完成Supplier to Zero level 1的验证",
        model:"验证模式",
        zdhcID:"ZDHC网关ID",
        openingHour:"年运行时间（小时）",
        fromFactoryAirport:"工厂到机场/高铁站的距离（汽车）",
        cmc:"化学品管理证书",
        numberofChemical:"工厂的化学品库存数量",
        mrsl:"MRSL符合性证书",
        chemicalUsed:"化学品使用数量",
        materials:"主要原辅料",
        serviceDate:"期望服务日期"
    },    
    zdhc:{
        formName:"ZDHC VERIFIED INCHECK LEVEL 1申请",
        experience:"ZDHC Verified Incheck Level 1 验证经验",
        performance:"过去三个月或连续三个季度的绩效检查报告是否完整",
        model:"验证类型",
        productionArea:"生产区面积",
        administration:"办公区面积",	
        chemicalStorageArea:"化学品储存区面积",
        other:"其他区域面积"	
    },
    ghg:{
        formName:"碳盘查信息收集表",
        year:"年份",
        energySource:"生产阶段消耗能源品种",
        multipleSite:"是否涉及多场址",
        involvesNewEnergy:"是否涉及使用新能源",
        onsitePowerGeneration:"是否涉及自发电",
        energySupplytoOtherSites:"是否涉及向外部单位转供电",
        carbonOffset:"是否涉及碳抵消",
        mainProduction:"主要生产设备",
        onsiteCanteen:"厂区内是否涉及食堂和宿舍",
        subcontractedProcess:"是否涉及生产外包",
        millsLease:"是否涉及厂房的出租",
        operationFacility:"主要生产辅助设施（如配电房，污水站，废气处理设施，中央空调，空压机等",
        sitEnergyMeteringMap:"请提供厂区能源计量图",
        chemicalInventory:"请提供主要化学品清单（含特种气体）",
        fireExtinguisher:"是否使用二氧化碳灭火器",
        fireSuppressionSystem:"是否使用七氟丙烷灭火系统",
        wawHandlingProcess:"请提供三废处理工艺"
    }
}
export default zh